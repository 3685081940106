.App {
    text-align: center;

    .App-header {
        align-items: center;
        background-color: #282c34;
        color: white;
        display: flex;
        flex-direction: row;
        font-size: calc(10px + 2vmin);
        justify-content: flex-start;
        padding: 3vh 0;

        .logo {
            animation: App-logo-spin infinite 20s linear;
            height: 18vmin;
            pointer-events: none;
        }

        .words {
            margin: 0;
            text-align: left;

            a {
                color: #61dafb;
                text-decoration: none;
            }
        }
    }

    .App-answer {
        padding-left: 20px;
        text-align: left;

        h2 {
            font-size: calc(5px + 3vmin);
            font-weight: bold;
        }

        pre {
            font-size: calc(10px + 3vmin);
        }
    }
}
