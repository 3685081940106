body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center; }
  .App .App-header {
    -webkit-align-items: center;
            align-items: center;
    background-color: #282c34;
    color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    font-size: calc(10px + 2vmin);
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    padding: 3vh 0; }
    .App .App-header .logo {
      -webkit-animation: App-logo-spin infinite 20s linear;
              animation: App-logo-spin infinite 20s linear;
      height: 18vmin;
      pointer-events: none; }
    .App .App-header .words {
      margin: 0;
      text-align: left; }
      .App .App-header .words a {
        color: #61dafb;
        text-decoration: none; }
  .App .App-answer {
    padding-left: 20px;
    text-align: left; }
    .App .App-answer h2 {
      font-size: calc(5px + 3vmin);
      font-weight: bold; }
    .App .App-answer pre {
      font-size: calc(10px + 3vmin); }

